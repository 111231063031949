import axios from "axios"
let vlogin_id = localStorage.getItem('login_id');
class ApNotificationService {


    list_notification_setting() {
        vlogin_id = localStorage.getItem('login_id');
        let form = {login_id:vlogin_id};
        return axios.post("ap_notification/list_notification_setting", form);

    }
    
    view_notification_setting(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_notification/view_notification_setting", form);

    }

    insert_notification_setting(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_notification/insert_notification_setting", form);

    }

    update_notification_setting(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_notification/update_notification_setting", form);

    }    

    list_notification_template() {
        vlogin_id = localStorage.getItem('login_id');
        let form = {login_id:vlogin_id};
        return axios.post("ap_notification/list_notification_template", form);

    }
    
    view_notification_template(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_notification/view_notification_template", form);

    }

    insert_notification_template(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_notification/insert_notification_template", form);

    }

    update_notification_template(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_notification/update_notification_template", form);

    }    


    list_notification_category() {
        vlogin_id = localStorage.getItem('login_id');
        let form = {login_id:vlogin_id};
        return axios.post("ap_notification/list_notification_category", form);

    }
    
    view_notification_category(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_notification/view_notification_category", form);

    }

    insert_notification_category(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_notification/insert_notification_category", form);

    }

    update_notification_category(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_notification/update_notification_category", form);

    }    


    
    list_notification_broadcast() {
        vlogin_id = localStorage.getItem('login_id');
        let form = {login_id:vlogin_id};
        return axios.post("ap_notification/list_notification_broadcast", form);

    }
    
    view_notification_broadcast(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_notification/view_notification_broadcast", form);

    }

    insert_notification_broadcast(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_notification/insert_notification_broadcast", form);

    }

    update_notification_broadcast(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_notification/update_notification_broadcast", form);

    }    

    send_notification_broadcast(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_notification/send_notification_broadcast", form);

    }    


    sendlist_notification_broadcast(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_notification/sendlist_notification_broadcast", form);

    }    

}

    
 

export default new ApNotificationService();